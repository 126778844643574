<template>
  <div
    :class="['hamburger', 'hamburger--spin', { 'is-active': modelValue }]"
    @click="$emit('update:modelValue', !modelValue)"
  >
    <div class="hamburger-box">
      <div class="hamburger-inner"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderHamburger',
  emits: ['update:modelValue'],
  props: {
    modelValue: Boolean
  }
}
</script>
