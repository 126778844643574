import dbase from '@/axios/api'
import loading from '@/utils/loading'
import message from '@/utils/message'

export default {
  namespaced: true,
  state() {
    return {
      backupTime: null,
      daysOfBackupLife: null,
      renewalDate: null
    }
  },
  getters: {
    backupTime: state => state.backupTime,
    daysOfBackupLife: state => state.daysOfBackupLife,
    renewalDate: state => state.renewalDate
  },
  mutations: {
    setData(state, payload) {
      const names = Object.keys(payload)
      names.forEach(field => {
        state[field] = payload[field]
      })
    }
  },
  actions: {
    async get({ commit }) {
      try {
        loading.start()
        const { data } = await dbase.get('/settings')
        loading.stop()
        commit('setData', data)
      } catch (e) {
        message.error('Ошибка при получении данных с сервера (настройки)', e)
      }
    },

    async updateSettings({ commit }, { backupTime, daysOfBackupLife }) {
      try {
        loading.start()
        const { data } = await dbase.patch('/settings', {
          backupTime,
          daysOfBackupLife
        })
        loading.stop()
        commit('setData', {
          backupTime: data.backupTime,
          daysOfBackupLife: data.daysOfBackupLife
        })
      } catch (e) {
        message.error('Ошибка при обновлении настроек', e)
      }
    },

    async saveRenewalDate({ commit }, payload) {
      try {
        loading.start()
        const { data } = await dbase.patch('/settings/time', {
          renewalDate: payload.renewalDate
        })
        loading.stop()
        commit('setData', {
          renewalDate: payload.renewalDate
        })
      } catch (e) {
        message.error('Ошибка при обновлении даты возобновления оплаты', e)
      }
    }
  }
}
