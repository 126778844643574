<template>
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.25 3L10.1925 4.0575L12.1275 6H4.5V7.5H12.1275L10.1925 9.435L11.25 10.5L15 6.75L11.25 3ZM1.5 1.5H7.5V0H1.5C0.675 0 0 0.675 0 1.5V12C0 12.825 0.675 13.5 1.5 13.5H7.5V12H1.5V1.5Z" fill="#AAAAAA"/>
  </svg>

</template>

<script>
export default {
name: 'LogoutIcon'
}
</script>
