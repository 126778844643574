import { createStore } from 'vuex'
import data from './data.module'
import auth from './auth.module'
import backups from './backups.module'
import settings from './settings.module'

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    data,
    auth,
    backups,
    settings
  }
})
