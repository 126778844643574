<template>
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 8.33333H6.66667V0H0V8.33333ZM0 15H6.66667V10H0V15ZM8.33333 15H15V6.66667H8.33333V15ZM8.33333 0V5H15V0H8.33333Z" fill="#AAAAAA"/>
  </svg>
</template>

<script>
export default {
  name: 'MainPageIcon'
}
</script>
