import axios from 'axios'
import { error } from '@/utils/error'
import { ElNotification } from 'element-plus'

const TOKEN_KEY = 'jwt-token'
const ID_KEY = 'localId'

export default {
  namespaced: true,
  state () {
    return {
      users: [],
      localId: localStorage.getItem(ID_KEY),
      token: localStorage.getItem(TOKEN_KEY) ?? '',
    }
  },
  getters: {
    token: state => state.token,
    isAuthenticated: (_, getters) => !!getters.token,
    localId: state => state.localId,
  },
  mutations: {
    setToken (state, token) {
      state.token = token
      localStorage.setItem(TOKEN_KEY, token)
    },
    logout (state) {
      state.token = null
      localStorage.removeItem(TOKEN_KEY)
      localStorage.removeItem(ID_KEY)
    },
    setUsers (state, users) {
      state.users = users
    },
    setLocalId (state, localId) {
      state.localId = localId
      localStorage.setItem(ID_KEY, localId)
    },
  },
  actions: {
    async login ({ commit, dispatch, getters }, payload) {
      try {
        const url = `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${process.env.VUE_APP_FB_API_KEY}`
        const { data } = await axios.post(url, { ...payload, returnSecureToken: true })
        commit('setToken', data.idToken)
        commit('setLocalId', data.localId)
      } catch (e) {
        ElNotification.error({
          title: 'Ошибка авторизации',
          message: error(e.response.data.error.message),
          customClass: 'message',
          duration: 10000000000
        })
        throw new Error(e)
      }
    },
    async registration ({ commit, dispatch }, payload) {
      try {
        const url = `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=${process.env.VUE_APP_FB_API_KEY}`
        const { data } = await axios.post(url, {
          email: payload.email,
          password: payload.password,
          returnSecureToken: true
        })
        commit('setToken', data.idToken)
        commit('setLocalId', data.localId)
      } catch (e) {
        ElNotification.error({
          title: 'Ошибка авторизации',
          message: error(e.response.data.error.message),
          customClass: 'message'
        })
        throw new Error(e)
      }
    },
  }
}
