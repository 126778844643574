<template>
  <section class="content auth" style="margin: auto">
    <router-view/>
  </section>
</template>

<script>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { ElNotification } from 'element-plus'
import AppCard from '../components/ui/AppCard'

export default {
  name: 'AuthLayout',
  components: { AppCard },
  setup() {
    const route = useRoute()

    const message = computed(() => route.query?.message)

    if (message.value === 'access-denied') {
      ElNotification({
        title: 'Доступ к странице блокирован',
        message: 'Необходимо войти в аккаунт',
        type: 'warning',
        customClass: 'message'
      })
    }

  }
}
</script>
