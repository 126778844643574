<template>
  <keep-alive>
    <component :is="isNeedAuth ? 'the-admin-header' : 'the-header'" />
  </keep-alive>
  <section class="content">
    <router-view/>
  </section>
</template>

<script>
import TheHeader from '../components/TheHeader'
import TheAdminHeader from '../components/TheAdminHeader'

export default {
  name: 'MainLayout',
  components: {
    TheHeader,
    TheAdminHeader
  },
  computed: {
    title() {
      return this.$route.meta.title
    },
    isNeedAuth() {
      return this.$route.meta.auth
    }
  }
}
</script>
