export function toNewLocalFormatData(responseData) {
  if (responseData === null) return []
  return Object.keys(responseData).map(key => ({
    ...responseData[key],
    id: responseData[key]._id
  }))
}

const BACKUP_CATEGORIES = {
  books: 'Книги',
  films: 'Фильмы',
  games: 'Игры',
  home: 'Главная',
  music: 'Музыка',
  titles: 'Заголовки категорий'
}

export function getCategoryName(category) {
  return BACKUP_CATEGORIES[category] || 'Invalid category name'
}
