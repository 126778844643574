import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      layout: 'main',
      auth: false
    }
  },
  {
    path: '/games',
    name: 'Games',
    component: function() {
      return import('../views/Games.vue')
    },
    meta: {
      layout: 'main',
      auth: false
    }
  },
  {
    path: '/games/:id',
    name: 'Game',
    component: function() {
      return import('../views/Item.vue')
    },
    meta: {
      layout: 'main',
      category: 'games',
      auth: false
    }
  },
  {
    path: '/films',
    name: 'Films',
    component: function() {
      return import('../views/Films.vue')
    },
    meta: {
      layout: 'main',
      auth: false
    }
  },
  {
    path: '/films/:id',
    name: 'Film',
    component: function() {
      return import('../views/Item.vue')
    },
    meta: {
      layout: 'main',
      category: 'films',
      auth: false
    }
  },
  {
    path: '/music',
    name: 'Music',
    component: function() {
      return import('../views/Music.vue')
    },
    meta: {
      layout: 'main',
      auth: false
    }
  },
  {
    path: '/music/:id',
    name: 'Song',
    component: function() {
      return import('../views/Item.vue')
    },
    meta: {
      layout: 'main',
      category: 'music',
      auth: false
    }
  },
  {
    path: '/books',
    name: 'Books',
    component: function() {
      return import('../views/Books.vue')
    },
    meta: {
      layout: 'main',
      auth: false
    }
  },
  {
    path: '/books/:id',
    name: 'Book',
    component: function() {
      return import('../views/Item.vue')
    },
    meta: {
      layout: 'main',
      category: 'books',
      auth: false
    }
  },
  {
    path: '/auth',
    name: 'Auth',
    component: function() {
      return import('../views/Auth.vue')
    },
    meta: {
      layout: 'auth',
      auth: false
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    redirect: '/admin/home',
    meta: {
      layout: 'main',
      auth: true
    },
    component: () => import('../views/Admin'),
    children: [
      {
        path: 'home',
        name: 'AdminHome',
        component: () => import('../views/AdminHome')
      },
      {
        path: 'games',
        name: 'AdminGames',
        component: () => import('../views/AdminGames')
      },
      {
        path: 'films',
        name: 'AdminFilms',
        component: () => import('../views/AdminFilms')
      },
      {
        path: 'music',
        name: 'AdminMusic',
        component: () => import('../views/AdminMusic')
      },
      {
        path: 'books',
        name: 'AdminBooks',
        component: () => import('../views/AdminBooks')
      },
      {
        path: 'settings',
        name: 'AdminSettings',
        component: () => import('../views/AdminSettings')
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  routes
})

router.beforeEach((to, from, next) => {
  const requireAuth = to.meta.auth

  if (requireAuth && store.getters['auth/isAuthenticated']) {
    next()
  } else if (requireAuth && !store.getters['auth/isAuthenticated']) {
    next('/auth?message=access-denied')
  } else if (
    store.getters['auth/isAuthenticated'] &&
    to.meta.layout === 'auth'
  ) {
    next('/admin?message=auth-exists')
  } else {
    next()
  }
})

export default router
