import { ElLoading } from 'element-plus'

function loading() {
  let loading = null
  return {
    start() {
      loading = ElLoading.service({
        fullscreen: true,
        customClass: 'app-loader'
      })
    },
    stop() {
      loading.close()
      loading = null
    }
  }
}

export default loading()
