import dbase from '@/axios/api'
import loading from '@/utils/loading'
import message from '@/utils/message'
import { capitalize } from '@/utils'

export default {
  namespaced: true,
  state() {
    return {
      list: [],
      categoriesList: []
    }
  },
  getters: {
    list: state => state.list,
    categoriesList: state => state.categoriesList
  },
  mutations: {
    setList(state, payload) {
      state.list = payload
    },
    setCategoriesList(state, payload) {
      state.categoriesList = payload
    }
  },
  actions: {
    async getList({ commit }) {
      try {
        loading.start()
        const { data } = await dbase.get('/backups')
        loading.stop()
        commit('setList', data)
      } catch (e) {
        message.error('Ошибка при получении данных с сервера (бэкапы)', e)
      }
    },
    async getCategoriesList({ commit }, backupDate) {
      try {
        loading.start()
        const { data } = await dbase.get('/backups', {
          params: {
            date: backupDate
          }
        })
        loading.stop()
        commit('setCategoriesList', data)
      } catch (e) {
        message.error(
          `Ошибка при получении данных с сервера (список бэкапов за дату ${backupDate})`,
          e
        )
      }
    },
    async recover({ commit, dispatch }, { backupDate, type }) {
      try {
        loading.start()
        const { data } = await dbase.get(`/backups/recover/${backupDate}`, {
          params: { type }
        })
        if (type === 'all') {
          await dispatch('data/getGames', null, { root: true })
          await dispatch('data/getBooks', null, { root: true })
          await dispatch('data/getFilms', null, { root: true })
          await dispatch('data/getMusic', null, { root: true })
          await dispatch('data/getHome', null, { root: true })
        } else {
          await dispatch(`data/get${capitalize(type)}`, null, { root: true })
        }
        loading.stop()
        message.success('Данные успешно восстановлены!')
      } catch (e) {
        message.error('Ошибка при восстановлении данных', e)
      }
    },
    async forceSave() {
      try {
        loading.start()
        const { data } = await dbase.get('/backups/save')
        loading.stop()
        message.success('Данные успешно сохранены!')
      } catch (e) {
        message.error('Ошибка при сохранении данных', e)
      }
    }
  }
}
