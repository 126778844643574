<template>
  <h2
    v-if="type === 'title'"
    class="main-title"
    v-html="text"
  >
  </h2>

  <h3
    v-if="type === 'subtitle'"
    class="main-subtitle"
    v-html="text"
  >
  </h3>

  <p
    v-if="type === 'bold-text'"
    class="home-text"
  >
    <b v-html="text"></b>
  </p>

  <p
    v-if="type === 'text'"
    class="home-text"
    v-html="text"
  >
  </p>

  <p
    v-if="type === 'list'"
    class="home-list"
    style="margin: 0;"
    v-html="text"
  >
  </p>
</template>

<script>
export default {
  name: 'HomeBlock',
  props: {
    text: {
      type: [String, Array],
      required: true
    },
    type: {
      type: String,
      required: true
    }
  }
}
</script>
