import dbaseCustom from '@/axios/api'
import { toNewLocalFormatData } from '@/utils/data'
import { error } from '@/utils/error'
import { ElLoading, ElMessage, ElNotification } from 'element-plus'

export default {
  namespaced: true,
  state() {
    return {
      loading: false,
      home: null,
      games: [],
      films: [],
      music: [],
      books: [],
      titles: []
    }
  },
  getters: {
    home: state => {
      if (state.home) {
        return state.home.slice().sort((a, b) => a.place - b.place)
      } else return null
    },
    games: state => state.games.slice().sort((a, b) => a.place - b.place),
    films: state => state.films.slice().sort((a, b) => a.place - b.place),
    music: state => state.music.slice().sort((a, b) => a.place - b.place),
    books: state => state.books.slice().sort((a, b) => a.place - b.place),
    titles: state => state.titles,
    isLoading: state => state.loading
  },
  mutations: {
    setData(state, { data, category }) {
      state[category] = data
    },
    setTitles(state, payload) {
      state.titles = toNewLocalFormatData(payload)
    },
    addItem(state, { data, category, id }) {
      state[category].forEach(item => {
        if (item.place >= data.place) {
          item.place++
        }
      })
      state[category].push({ ...data, id })
    },
    setLoadStatus(state, status) {
      state.loading = status
    },
    removeItem(state, { id, category }) {
      const { place } = state[category].find(item => item.id === id)
      const idx = state[category].findIndex(item => item.id === id)
      state[category].splice(idx, 1)
      state[category].forEach(item => {
        if (item.place > place) {
          item.place--
        }
      })
    },
    editItem(state, { data, category, id }) {
      const idx = state[category].findIndex(item => item.id === id)
      const { place: oldPlace } = state[category].find(item => item.id === id)
      const { place } = data

      if (oldPlace > place) {
        state[category].forEach(item => {
          if (item.place >= place && item.place < oldPlace) {
            item.place++
          }
        })
      } else if (oldPlace < place) {
        state[category].forEach(item => {
          if (item.place > oldPlace && item.place <= place) {
            item.place--
          }
        })
      }

      data = JSON.parse(JSON.stringify(data))

      state[category][idx] = data
    },
    changeTitle(state, { category, title, id }) {
      const idx = state.titles.findIndex(item => item.id === id)
      state.titles[idx] = { category, title, id }
    },
    addSection(state, { data, id }) {
      data.id = id
      state.home.push(data)
    },
    moveSection(state, { type, id }) {
      if (type === 'up') {
        const idx = state.home.findIndex(item => item.id === id)
        const prevIdx = state.home.findIndex(
          item => item.place === state.home[idx].place - 1
        )
        state.home[idx].place -= 1
        state.home[prevIdx].place += 1
      } else {
        const idx = state.home.findIndex(item => item.id === id)
        const nextIdx = state.home.findIndex(
          item => item.place === state.home[idx].place + 1
        )
        state.home[idx].place += 1
        state.home[nextIdx].place -= 1
      }
    },
    changeSection(state, { id, text }) {
      const idx = state.home.findIndex(item => item.id === id)
      state.home[idx].text = text
    },
    deleteSection(state, id) {
      const idx = state.home.findIndex(item => item.id === id)
      state.home.splice(idx, 1)
    }
  },
  actions: {
    async getTitles({ commit }) {
      try {
        const { data } = await dbaseCustom.get('/titles')
        commit('setTitles', data)
      } catch (e) {
        ElNotification.error({
          title: 'Ошибка при получении данных с сервера (заголовки)',
          message: error(e),
          customClass: 'app-message'
        })
      }
    },

    async changeTitle({ commit }, { category, title, id }) {
      try {
        const { data } = await dbaseCustom.patch(`/titles/${id}`, {
          category,
          title
        })
        commit('changeTitle', { category, title, id })
        ElMessage({
          message: 'Изменения сохранены!',
          type: 'success',
          customClass: 'app-message'
        })
      } catch (e) {
        ElMessage({
          message: error(e),
          type: 'error',
          customClass: 'app-message'
        })
      }
    },

    async changeSection({ commit }, { id, text }) {
      try {
        const { data } = await dbaseCustom.patch(`/home/${id}`, { text })
        commit('changeSection', { id, text })
        ElMessage({
          message: 'Изменения сохранены!',
          type: 'success',
          customClass: 'app-message'
        })
      } catch (e) {
        ElMessage({
          message: error(e),
          type: 'error',
          customClass: 'app-message'
        })
      }
    },

    async deleteSection({ commit }, id) {
      try {
        let loading = ElLoading.service({
          fullscreen: true,
          customClass: 'app-loader'
        })
        const { data } = await dbaseCustom.delete(`/home/${id}`)
        commit('deleteSection', id)
        loading.close()
        ElMessage({
          message: 'Секция удалена!',
          type: 'success',
          customClass: 'app-message'
        })
      } catch (e) {
        ElMessage({
          message: error(e),
          type: 'error',
          customClass: 'app-message'
        })
      }
    },

    async addSection({ commit }, data) {
      try {
        const { data: response } = await dbaseCustom.post(`/home`, data)
        commit('addSection', { data, id: response._id })
        ElMessage({
          message: 'Секция успешно добавлена!',
          type: 'success',
          customClass: 'app-message'
        })
      } catch (e) {
        ElMessage({
          message: error(e),
          type: 'error',
          customClass: 'app-message'
        })
      }
    },

    async addItem({ commit }, { data, category }) {
      try {
        const { data: response } = await dbaseCustom.post(`/${category}`, data)
        commit('addItem', { data, category, id: response._id })
        ElMessage({
          message: 'Позиция успешно добавлена!',
          type: 'success',
          customClass: 'app-message'
        })
      } catch (e) {
        ElMessage({
          message: error(e),
          type: 'error',
          customClass: 'app-message'
        })
      }
    },

    async removeItem({ commit }, { category, id }) {
      try {
        const { data } = await dbaseCustom.delete(`/${category}/${id}`)
        commit('removeItem', { category, id })
        ElMessage({
          message: 'Позиция успешно удалена!',
          type: 'success',
          customClass: 'app-message'
        })
      } catch (e) {
        ElMessage({
          message: error(e),
          type: 'error',
          customClass: 'app-message'
        })
      }
    },

    async editItem({ commit }, { data, category }) {
      try {
        const id = data.id
        const { data: response } = await dbaseCustom.patch(
          `/${category}/${id}`,
          data
        )
        commit('editItem', { data, category, id })
        ElMessage({
          message: 'Позиция успешно отредактирована!',
          type: 'success',
          customClass: 'app-message'
        })
      } catch (e) {
        ElMessage({
          message: error(e),
          type: 'error',
          customClass: 'app-message'
        })
      }
    },

    async moveSection({ commit }, { type, id }) {
      try {
        let loading = ElLoading.service({
          fullscreen: true,
          customClass: 'app-loader'
        })
        const { data } = await dbaseCustom.patch(`/home/${id}?move=${type}`)
        commit('moveSection', { type, id })
        loading.close()
      } catch (e) {
        ElNotification.error({
          title: 'Ошибка при изменении данных на сервере (главная)',
          message: error(e),
          customClass: 'app-message'
        })
      }
    },

    async getGames({ commit }) {
      try {
        const { data } = await dbaseCustom.get('/games')
        commit('setData', {
          data: toNewLocalFormatData(data),
          category: 'games'
        })
      } catch (e) {
        ElNotification.error({
          title: 'Ошибка при получении данных с сервера (игры)',
          message: error(e),
          customClass: 'app-message'
        })
      }
    },

    async getBooks({ commit }) {
      try {
        const { data } = await dbaseCustom.get('/books')
        commit('setData', {
          data: toNewLocalFormatData(data),
          category: 'books'
        })
      } catch (e) {
        ElNotification.error({
          title: 'Ошибка при получении данных с сервера (книги)',
          message: error(e),
          customClass: 'app-message'
        })
      }
    },

    async getFilms({ commit }) {
      try {
        const { data } = await dbaseCustom.get('/films')
        commit('setData', {
          data: toNewLocalFormatData(data),
          category: 'films'
        })
      } catch (e) {
        ElNotification.error({
          title: 'Ошибка при получении данных с сервера (фильмы)',
          message: error(e),
          customClass: 'app-message'
        })
      }
    },

    async getMusic({ commit }) {
      try {
        const { data } = await dbaseCustom.get('/music')
        commit('setData', {
          data: toNewLocalFormatData(data),
          category: 'music'
        })
      } catch (e) {
        ElNotification.error({
          title: 'Ошибка при получении данных с сервера (музыка)',
          message: error(e),
          customClass: 'app-message'
        })
      }
    },

    async getHome({ commit }) {
      try {
        let loading = ElLoading.service({
          fullscreen: true,
          customClass: 'app-loader'
        })
        const { data } = await dbaseCustom.get('/home')
        commit('setData', {
          data: toNewLocalFormatData(data),
          category: 'home'
        })
        loading.close()
      } catch (e) {
        ElNotification.error({
          title: 'Ошибка при получении данных с сервера (главная)',
          message: error(e),
          customClass: 'app-message'
        })
      }
    }
  }
}
