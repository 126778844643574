<template>
  <component v-if="layoutType" :is="layoutComponentName"></component>
</template>

<script>
import MainLayout from './layout/MainLayout'
import AuthLayout from './layout/AuthLayout'

export default {
  components: {
    MainLayout,
    AuthLayout
  },
  computed: {
    layoutType() {
      return this.$route.meta.layout ?? null
    },
    layoutComponentName() {
      return `${this.layoutType}-layout`
    }
  },
}
</script>
