<template>
  <header class="header">
    <div class="header__title-nav">
      <h1 class="header__title">Админ-панель</h1>
    </div>

    <HeaderHamburger v-model="isMenuActive" />

    <nav
      :class="['header__nav', { active: isMenuActive }]"
      @click="isMenuActive = false"
    >
      <router-link class="header__nav-link" to="/">
        <MainPageIcon style="margin-right: 5px;" />Просмотр
      </router-link>

      <router-link class="header__nav-link" to="/admin/home"
        >Главная</router-link
      >
      <router-link class="header__nav-link" to="/admin/games">Игры</router-link>
      <router-link class="header__nav-link" to="/admin/films"
        >Фильмы</router-link
      >
      <router-link class="header__nav-link" to="/admin/music"
        >Музыка</router-link
      >
      <router-link class="header__nav-link" to="/admin/books"
        >Книги</router-link
      >
      <router-link class="header__nav-link" to="/admin/settings"
        >Настройки</router-link
      >
      <a href="#" class="header__nav-link" @click.prevent="logout"
        ><LogoutIcon style="margin-right: 5px;" />Выйти</a
      >
    </nav>
  </header>
</template>

<script>
import LogoutIcon from './icons/LogoutIcon'
import MainPageIcon from './icons/MainPageIcon'
import HeaderHamburger from '@/components/HeaderHamburger'

export default {
  name: 'TheAdminHeader',
  components: {
    LogoutIcon,
    MainPageIcon,
    HeaderHamburger
  },
  data() {
    return {
      isMenuActive: false
    }
  },
  methods: {
    logout() {
      this.$store.commit('auth/logout')
      this.$router.push('/')
    }
  },
  watch: {
    isMenuActive: value => {
      if (value) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = ''
      }
    }
  }
}
</script>
