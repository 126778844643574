<template>
  <header class="header">
    <div class="header__title-nav">
      <h1 class="header__title">Hyperx Library</h1>
    </div>

    <HeaderHamburger v-model="isMenuActive" />

    <nav
      :class="['header__nav', { active: isMenuActive }]"
      @click="isMenuActive = false"
    >
      <router-link v-if="isAuth" class="header__nav-link" to="/admin">
        <AdminIcon style="margin-right: 5px;" /> Администрирование
      </router-link>
      <router-link class="header__nav-link" to="/">Главная</router-link>
      <router-link class="header__nav-link" to="/games">Игры</router-link>
      <router-link class="header__nav-link" to="/films">Фильмы</router-link>
      <router-link class="header__nav-link" to="/music">Музыка</router-link>
      <router-link class="header__nav-link" to="/books">Книги</router-link>
    </nav>
  </header>
</template>

<script>
import AdminIcon from './icons/AdminIcon'
import HeaderHamburger from '@/components/HeaderHamburger'

export default {
  name: 'TheHeader',
  components: {
    AdminIcon,
    HeaderHamburger
  },
  data() {
    return {
      isMenuActive: false
    }
  },
  computed: {
    isAuth() {
      return this.$store.getters['auth/isAuthenticated']
    }
  },
  watch: {
    isMenuActive: value => {
      if (value) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = ''
      }
    }
  }
}
</script>
