<template>
  <div :class="['card', { form }]">
    <h2
      v-if="title"
      class="card__title"
    >
      {{ title }}
    </h2>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AppCard',
  props: {
    title: {
      type: String,
      required: false
    },
    padding: {
      type: Array,
      required: false,
      default: [50, 50]
    },
    form: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
