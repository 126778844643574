const STORAGE_URL = `https://firebasestorage.googleapis.com/v0/b/hyperx-lib.appspot.com/o/`

export function isURL(str) {
  const pattern = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
  return !!pattern.test(str)
}

export function convertToURL(imgName) {
  return `${STORAGE_URL}${imgName}?alt=media`
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}