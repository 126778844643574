<template>
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.5 1.78125C8.5875 1.78125 9.46875 2.6625 9.46875 3.75C9.46875 4.8375 8.5875 5.71875 7.5 5.71875C6.4125 5.71875 5.53125 4.8375 5.53125 3.75C5.53125 2.6625 6.4125 1.78125 7.5 1.78125ZM7.5 10.2187C10.2844 10.2187 13.2188 11.5875 13.2188 12.1875V13.2188H1.78125V12.1875C1.78125 11.5875 4.71562 10.2187 7.5 10.2187ZM7.5 0C5.42812 0 3.75 1.67812 3.75 3.75C3.75 5.82188 5.42812 7.5 7.5 7.5C9.57188 7.5 11.25 5.82188 11.25 3.75C11.25 1.67812 9.57188 0 7.5 0ZM7.5 8.4375C4.99687 8.4375 0 9.69375 0 12.1875V15H15V12.1875C15 9.69375 10.0031 8.4375 7.5 8.4375Z" fill="#AAAAAA"/>
  </svg>
</template>

<script>
export default {
  name: 'AdminIcon'
}
</script>
