import { ElMessage } from 'element-plus'
import { error } from '@/utils/error'

export default {
  success(messageText) {
    ElMessage({
      message: messageText,
      type: 'success',
      customClass: 'app-message'
    })
  },
  error(messageText, e) {
    ElMessage({
      message: error(e),
      type: 'error',
      customClass: 'app-message'
    })
  }
}
