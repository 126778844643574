<template>
  <app-card>
    <HomeBlock
      v-for="block in content"
      :key="block.id"
      :type="block.type"
      :text="block.text"
    />
  </app-card>
</template>

<script>
import HomeBlock from '../components/ui/HomeBlock'
import AppCard from '@/components/ui/AppCard'

export default {
  name: 'Home',
  components: {
    HomeBlock,
    AppCard
  },
  computed: {
    content () {
      return this.$store.getters['data/home']
    }
  },
  async mounted () {
    if (this.content === null) {
      await this.$store.dispatch('data/getHome')
    }
  }
}
</script>
